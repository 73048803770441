<template>
  <add-scripts
    :scripts="scripts"
    v-on:success="scriptSuccess"
    v-on:error="scriptError"
  ></add-scripts>
  <List />
</template>

<script>
import List from '@/views/ListView.vue'
import AddScripts from '@/addScripts.vue';
export default {
  name: 'App',
  components: {
    List,
    AddScripts,
  },
  data(){
    return{
      scripts : [
        'https://telegram.org/js/telegram-web-app.js'
      ],
    }
  },
  methods:{
    scriptSuccess(src){
      console.log(`Loaded Script: ${src}`);
      // do some stuff
    },
    scriptError(src){
      console.log(`Script Error! ${src}`);
      // do some stuff
    }
  },

}
</script>
