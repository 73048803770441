import { createApp } from 'vue'
import App from './App.vue'
import VueAwesomePaginate from "vue-awesome-paginate";
import "vue-awesome-paginate/dist/style.css";
import "@/assets/styles/main.scss"
import axios from 'axios'
import VueAxios from 'vue-axios'
import i18n from './i18n'

createApp(App)
    .use(i18n)
    .use(VueAwesomePaginate)
    .use(VueAxios, axios)
    .mount('#app')
