<template>
  <div class="modal remove">
    <div class="modal__block">
      <p style="white-space: nowrap">
        {{ $t('copy_link') }}
      </p>
      <div class="btn__group">
        <button @click="cancel">
          {{ $t('ok') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modal-copy',
  methods: {
    cancel() {
      this.$emit("close");
    }
  },
}
</script>