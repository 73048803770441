<template>
  <div class="modal remove">
    <div class="modal__block">
      <p style="white-space: nowrap">
        {{ $t('delete_link') }}
      </p>
      <div class="btn__group">
        <button @click="deleteItem">
          {{ $t('delete') }}
        </button>
        <button @click="cancel">
          {{ $t('cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalDelete',
  props: ['item'],
  data() {
    return {
    }
  },
  methods: {
    deleteItem() {
      this.$emit("delete", {
        deleteItem: `delete;${this.item.id}`
      });
    },
    cancel() {
      this.$emit("close");
    }
  },
}
</script>