export const cookiesAction = {
    setCookie(name, value, options = {}) {

        options = {
            path: '/',
            expires: 10 * 365 * 24 * 60 * 60,
            ...options
        };

        if (options.expires instanceof Date) {
            options.expires = options.expires.toUTCString();
        } else if (typeof options.expires === 'number') {
            const date = new Date();
            date.setTime(date.getTime() + options.expires * 1000);
            options.expires = date.toUTCString();
        }

        let updatedCookie = encodeURIComponent(name) + "=" + encodeURIComponent(value);

        for (let optionKey in options) {
            updatedCookie += "; " + optionKey;
            let optionValue = options[optionKey];
            if (optionValue !== true) {
                updatedCookie += "=" + optionValue;
            }
        }

        document.cookie = updatedCookie;
    },
    /* eslint-disable */
    getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    },
    deleteCookie(name) {
        cookiesAction.setCookie(name, "", {
            path: '/',
            'max-age': -1
        });
    }
};
