<template>
  <div class="modal remove">
    <div class="modal__block">
      <p style="white-space: nowrap">{{ $t('add_domain') }}</p>
      <div class="btn__group">
        <button @click="addDomain">
          {{ $t('yes') }}
        </button>
        <button @click="cancel">
          {{ $t('cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modal-add-domain',
  props: ['item'],
  data() {
    return {
    }
  },
  methods: {
    addDomain() {
      window.Telegram.WebApp.sendData(`edit_link_add_domain;${this.item.id}`);
    },
    cancel() {
      this.$emit("close");
    }
  },
}
</script>