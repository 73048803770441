<template>
  <div class="modal edit">
    <div class="modal__block">
      <h3>{{ $t('preview') }}</h3>
      <div class="">
        <iframe width="100%" height="450" :src="urlToPreview"></iframe>
      </div>
      <div class="btn__group">
        <button @click="cancel">
          {{ $t('cancel') }}
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'modal-preview',
  props: ['urlToPreview'],
  methods: {
    cancel() {
      this.$emit("close");
    },
  },
}
</script>